<template>
  <div class="flex-grow-1 d-flex flex-column justify-content-center">
    <p class="mb-32">Selamat datang di E-learning. Silakan masuk ke ruang:</p>

    <div class="d-grid">
      <router-link to="/siswa/login" class="btn btn-lg btn-primary mb-16">
        Siswa
      </router-link>
      <router-link
        to="/guru/login"
        class="btn btn-lg btn-outline-primary mb-16"
      >
        Guru
      </router-link>
      <router-link
        to="/admin/login"
        class="btn btn-lg btn-outline-primary mb-16"
      >
        Admin
      </router-link>
    </div>
  </div>
</template>

<script>
export default {
  metaInfo: {
    title: 'Login',
  },
};
</script>
